<template>
  <div class="hoteCatelList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>签约列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- <div class="search">
      <div>
        <span>用户id</span>
        <el-input
          placeholder="请输入用户id"
          v-model.trim="list.userId"
        ></el-input>
      </div>
      <div>
        <span>用户手机号</span>
        <el-input
          placeholder="请输入用户手机号"
          v-model.trim="list.phone"
        ></el-input>
      </div>
      <div>
        <span>用户真实姓名</span>
        <el-input
          placeholder="请输入用户真实姓名"
          v-model.trim="list.trueName"
        ></el-input>
      </div>

      <el-button type="primary" @click="onSearch">搜索</el-button>
    </div> -->
    <el-form :inline="true" class="top" style="margin-top:20px">
        <el-form-item label="用户id:">
          <el-input v-model="list.userId"  placeholder="请输入用户id"></el-input>
        </el-form-item>
        <el-form-item label="用户手机号:">
          <el-input v-model="list.phone"  placeholder="请输入用户手机号"></el-input>
        </el-form-item>
        <el-form-item label="用户真实姓名:" >
          <el-input v-model="list.trueName" placeholder="请输入用户真实姓名"></el-input>
        </el-form-item>

        <el-form-item >
      <el-button type="primary" @click="onSearch">搜索</el-button>
        </el-form-item>
      </el-form>
    <el-table :data="tableData" style="width: 100%" border>
      <el-table-column align="center" prop="userId" label="用户Id">
      </el-table-column>
      <el-table-column align="center" prop="userPhone" label="会员手机号">
      </el-table-column>
      <el-table-column align="center" prop="trueName" label="真实姓名">
      </el-table-column>
      <el-table-column prop="idCard" label="身份证"> </el-table-column>

      <el-table-column align="center" prop="phone" label="手机号">
      </el-table-column>
      <el-table-column align="center" prop="bankName" label="银行名称">
      </el-table-column>
      <el-table-column align="center" prop="bankNo" label="卡号">
      </el-table-column>
      <el-table-column align="center" label="身份证正面">
        <template slot-scope="{ row }">
          <el-image
            class="cateImg"
            style="width: 60px; height: 60px"
            :src="row.idCardZ"
            :preview-src-list="[row.idCardZ]"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column align="center" label="身份证反面">
        <template slot-scope="{ row }">
          <el-image
            class="cateImg"
            style="width: 60px; height: 60px"
            :src="row.idCardF"
            :preview-src-list="[row.idCardF]"
          ></el-image>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="list.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
  </div>
</template>
    <script>
import { getUserBankList } from "../../api/Finance";
import sendImage from "../../components/sendImage.vue";
export default {
  components: { sendImage },
  name: "hoteCatelList",
  data() {
    return {
      pagination: {},
      list: {
        currentPage: 1,
        pageSize: 5,
        userId: "",
        phone: "",
        trueName: "",
      },
      tableData: [],
    };
  },
  created() {
    this.getHotelCateList();
  },
  methods: {
    async getHotelCateList() {
      const { data } = await getUserBankList(this.list);
      this.pagination = data.pagination;
      this.tableData = data.list;
    },
    onSearch() {
      this.getHotelCateList();
    },
    onaddImageStr(e, type) {
      console.log(e, type);
      if (type == 1) {
        this.src = e;
      } else if (type == 2) {
        this.editform.bankLogo = e;
      }
    },

    sendAvatar() {
      this.$refs.fileRef.click();
    },

    async onAddCateOk() {
      if (this.addform.bankName === "" || this.src === "") {
        this.$message({
          type: "warning",
          message: "请把数据填写完整!",
        });
        return;
      }
      const { data } = await addBank({
        bankName: this.addform.bankName,
        logo: this.src,
      });
      if (data.code === 0) {
        this.$message({
          type: "success",
          message: "添加成功!",
        });
        (this.addform.bankName = ""), (this.src = ""), this.getHotelCateList();
      }
      this.addClassADialogVisible = false;
      this.getHotelCateList();
      this.addform.bankName = "";
      this.src = "";
    },
    async onEditCate(row) {
      this.editform = { ...this.editform, ...row };
      this.editClassADialogVisible = true;
    },
    async onEditCateOk() {
      if (this.editform.bankName === "" || this.editform.bankLogo === "") {
        return this.$message.warning("请把数据填写完整");
      }
      const { data } = await addBank({
        ...this.editform,
        logo: this.editform.bankLogo,
      });
      if (data.code == 0) {
        this.$message({
          type: "success",
          message: "修改成功!",
        });
        this.editform.bankName = "";
        this.editform.bankLogo = "";
        this.editClassADialogVisible = false;
        this.getHotelCateList();
      }
    },
    onDelCate(row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await deleteBank({
            bankId: row.bankId,
          });
          this.getHotelCateList();
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    handleSizeChange(size) {
      this.list.pageSize = size;
      this.getHotelCateList();
    },
    handleCurrentChange(Current) {
      this.list.currentPage = Current;
      this.getHotelCateList();
    },
  },
};
</script>
    <style lang="less" scoped>
.hoteCatelList {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .search {
    margin-top: 50px;
    .el-input {
      width: 500px;
      margin: 0 30px;
    }
  }
  /deep/.el-table {
    margin-top: 50px;
    .el-table__body-wrapper {
      table {
        td {
          .cateImg {
            width: 80px;
            height: 80px;
          }
        }
      }
    }
  }
//   /deep/.el-form {
//     .el-form-item {
//       .el-form-item__content {
//         .elbtn {
//           margin-left: 10px;
//           background-color: unset;
//           width: 110px;
//           height: 110px;
//           text-align: center;
//           line-height: 110px;
//           color: #000;
//           border: 1px solid #ccc;
//         }
//         display: flex;
//         .img {
//           position: relative;
//           margin: 0 20px;
//           height: 100px;
//           width: 100px;
//           img {
//             height: 100px;
//             width: 100px;
//             border: 1px solid #c0ccda;
//           }
//           div {
//             position: absolute;
//             top: 0;
//             right: 5px;
//             height: 10px;
//             width: 10px;
//             z-index: 100;
//           }
//         }
//         .addImg {
//           display: inline-block;
//           width: 100px;
//           height: 100px;
//           border: 1px dashed #c0ccda;
//           text-align: center;
//           line-height: 100px;
//           cursor: pointer;
//           i {
//             width: 50px;
//             height: 50px;
//             font-size: 20px;
//             color: #c0ccda;
//           }
//         }
//         span {
//           margin-left: 30px;
//           color: red;
//         }
//       }
//     }
//   }

  .el-pagination {
    margin-top: 50px;
    float: right;
  }
  .btn {
    margin-top: 50px;
  }
}
</style>