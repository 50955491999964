import request from '../utils/request'
import baseUrl from './baseUrl'
export const rechargeRecordApi = (data) => request({
  url: baseUrl + '/balanceInfo/selectForBack',
  method: 'POST',
  data
})
export const withdrawalRecordApi = (data) => request({
  url: baseUrl + '/cash/selectForBack',
  method: 'POST',
  data
})
export const withdrawalRefuse = (data) => request({
  url: baseUrl + '/cash/modifyRefuse',
  method: 'POST',
  data
})
export const withdrawalTure = (data) => request({
  url: baseUrl + '/cash/modifyAdopt',
  method: 'POST',
  data
})
export const exportExcel = (data) => request({
  url: baseUrl + '/cash/exportExcel',
  method: 'POST',
  data,
  responseType: "blob"
})
// 财务管理-银行管理-列表
export const getBankList = (data) => request({
  url: baseUrl + '/bank/selectForBack',
  method: 'POST',
  data
})
// 财务管理-银行管理-列表添加/修改
export const addBank = (data) => request({
  url: baseUrl + '/bank/add',
  method: 'POST',
  data
})
// 财务管理-银行管理-列表删除
export const deleteBank = (data) => request({
  url: baseUrl + '/bank/deleteById',
  method: 'POST',
  data
})
// 签约列表
export const getUserBankList = (data) => request({
  url: baseUrl + '/userBank/selectForBack',
  method: 'POST',
  data
})

//税费列表
export const selectForTax = (data) => request({
  url: baseUrl + '/cash/selectForTax',
  method: 'POST',
  data
})

export const selectTaxExcel = (data) => request({
  url: baseUrl + '/cash/selectTaxExcel',
  method: 'POST',
  data,
  responseType: "blob"
})